<template>
  <div>
    <h1 class="text-white mb-10">{{ $t("GENERAL.CONTACT") }}</h1>

    <div class="card card-custom gutter-b card-stretch mt-10">
      <!--begin::Header-->

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column p-8">
        <!--begin::Chart-->

        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-0" :label="$t('GENERAL.NAME') + ':'" label-for="input-1">
              <b-form-input id="input-1" v-model="form.name" type="text" required placeholder="..."></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" :label="$t('GENERAL.EMAIL') + ':'" label-for="input-1" :description="$t('GENERAL.CONTACT_EMAIL_MESSAGE')">
              <b-form-input id="input-1" v-model="form.email" type="email" required placeholder="..."></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" :label="$t('GENERAL.MESSAGE') + ':'" label-for="input-2">
              <b-form-textarea id="textarea-rows" v-model="form.message" required placeholder="" rows="8"></b-form-textarea>
            </b-form-group>

            <b-button type="submit" class="float-right ml-4 btn-light-success font-weight-bolder" variant="primary">{{ $t("GENERAL.SEND") }}</b-button>
          </b-form>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<style scoped>
.bg-translucent {
  background: rgba(255, 255, 255, 0.8);
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TheToaster from "@/core/services/the-toaster";

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // console.log(this.form);
      this.$store.dispatch("contact/send", this.form).then(() => {
        TheToaster.success("GENERAL.MESSAGE_SUCCESS");
        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
      });

      // alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // { title: "Cropper", route: "auth-signin" },
      // { title: "fuuuuuuu" }
    ]);
  },
};
</script>
